import React from 'react';

import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Link } from 'react-router-dom';

import Dashboard from '../Layouts/Dashboard';
import CalendarBanner from '../../components/Home/CalendarBanner/CalendarBanner';
import NavigationPills from '../../components/Home/NavigationPills/NavigationPills';
import LogDashboard from '../../components/Home/LogDashboard/LogDashboard';
import SeenConversationCheck from '../../components/Home/SeenConversationCheck/SeenConversationCheck';

import { getDialogflowEngagements } from '../../reducers/engagements';

const DialogflowDashboard = props => {

	const { StartDate, EndDate } = props.dates;

    React.useEffect(() => {
		props.getEngagements(StartDate, EndDate);
    }, [StartDate, EndDate]);

	const getDataByType = () => {
		switch(props.engagements.typeOfEngagement) {
			case 'successful':
				return props.engagements.data.dialogflow.successful.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						scheduledAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						scheduledFor: engagement.ProposedDate !== '-' && engagement.ProposedTime !== '-' ? moment(`${engagement.ProposedDate} ${engagement.ProposedTime}`, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm A') : 'No date provided',
						status: engagement.Status,
						seeConversation: <Link to={`dialogflow/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			case 'fails':
				return props.engagements.data.dialogflow.fails.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						firedAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						flagged: engagement.Flagged?.Status || 'NOT_SPECIFIED',
						status: engagement.Status,
						seeConversation: <Link to={`dialogflow/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			case 'bails':
				return props.engagements.data.dialogflow.bails.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						firedAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						flagged: engagement.Flagged?.Status || 'NOT_SPECIFIED',
						status: engagement.Status,
						seeConversation: <Link to={`dialogflow/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			default:
				return null;
		}
	};

	const titles = {
		fails: { label: 'Unresolved/Failed conversations', color: 'danger' },
		successful: { label: 'Successful appointments/Route to humans', color: 'success' },
		bails: { label: 'Bailed Conversations', color: 'info' },
	};

    return(
		<Dashboard>
			<CalendarBanner 
				dates={props.dates}
				total={props.total}
			/>
			<NavigationPills
				engagements={props.engagements.data.dialogflow}
				total={props.total}
				loading={props.engagements.loading}
				typeOfEngagement={props.engagements.typeOfEngagement}
				titles={titles}
			/>
			<SeenConversationCheck 
				showSeenConversations={props.showSeenConversations}
				loading={props.engagements.loading}
			/>
			<LogDashboard
				engagements={getDataByType()}
				loading={props.engagements.loading}
				typeOfEngagement={props.engagements.typeOfEngagement}
				showSeenConversations={props.showSeenConversations}
			/>
		</Dashboard>
    );
};

const mapStateToProps = state => {
	const total = Object.values(state.engagements.data.dialogflow).reduce((acum, currentValue) => acum += currentValue.length, 0);
    const showSeenConversations = state.engagements.showSeenConversations;
	return { engagements: state.engagements, dates: state.dates, showSeenConversations, total };
};

const mapDispatchToProps = dispatch => ({
	getEngagements: (StartDate, EndDate) => dispatch(getDialogflowEngagements(StartDate, EndDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogflowDashboard);
