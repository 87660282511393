import moment from 'moment-timezone';

const dates = {
    StartDate: moment().tz('America/Los_Angeles').startOf('isoWeek').format('YYYY-MM-DD'),
    EndDate: moment().tz('America/Los_Angeles').endOf('isoWeek').format('YYYY-MM-DD')
}

export default function(state = dates, action){
    switch(action.type){
        case 'SET_DATES': {
            return { ...action.payload.dates };
        }
        default:
            return { ...state };
    }
}
