import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

import './ChatBox.scoped.scss';

const ChatBox = props => {

    const mapConversations = conversations => {
        return conversations.map((conversation, index) => {
            if(conversation.currentTask === 'auto_bailed'){
                return(
                    <React.Fragment key={index}>
                        <div className={'d-flex flex-column align-items-center passed-to-agent mt-4'}>
                            ---- Passed To Agent at: {moment.tz(conversation.datetime, 'Etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A')} ----
                        </div>
                    </React.Fragment>
                );
            } else if(conversation.currentTask === 'agent_handles') {
                return(
                    <React.Fragment key={index}>
                            <div className={'d-flex flex-column align-items-center passed-to-agent mt-4'} style={{ color: '#003366' }}>
                                ---- Agent {conversation.say} picked up at: {moment.tz(conversation.datetime, 'Etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A')} ----
                            </div>
                    </React.Fragment>
                );
            } else if(conversation.currentTask === 'flex_user_says') {
                return(
                    <>
                        <div className='d-flex flex-column align-items-start'>
                            <div className='mt-2 rounded p-5 text-dark-50 font-weight-bold font-size-lg text-left max-w-400px user-says'>
                                { conversation.say }
                            </div>
                            <div className='datetime user'>
                                <div className="row">
                                    <div className="col-12">
                                        <span className='name'>User in flex @</span>
                                        { moment.tz(conversation.datetime, 'Etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A') }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else if(conversation.currentTask === 'flex_agent_says') {
                return(
                    <>
                        <div className='d-flex flex-column align-items-end'>
                            <div className='mt-2 rounded p-5 text-dark-50 font-weight-bold font-size-lg text-left max-w-400px agent-says'>
                                { conversation.say }
                            </div>
                            <div className='datetime bot'>
                                <div className="row">
                                    <div className="col-12">
                                        <span className='name'>Agent in flex @</span>
                                        { moment.tz(conversation.datetime, 'Etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A') }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        <div className={'d-flex flex-column' + (conversation.type ? ' align-items-start' : ' align-items-end')}>
                            { conversation.typeOfLead == 'NAF' && <div className='badge badge-primary'>NAF</div> }
                            { conversation.typeOfLead == 'Zigzy' && <div className='badge badge-danger'>Zigzy</div> }
                        </div>
                        <div className={'d-flex flex-column' + (conversation.type ? ' align-items-start' : ' align-items-end')}>
                            <div className={`mt-2 rounded p-5 text-dark-50 font-weight-bold font-size-lg text-left max-w-400px ${(conversation.type ? 'bg-light-primary' : 'bg-light-success')}`}>
                                { conversation.say }
                            </div>
                            <div className={'datetime' + (conversation.type ? ' user' : ' bot')}>
                                <div className="row">
                                    <div className="col-12">
                                        <span className='name'>{ (conversation.type ? (props.userName || 'User') : 'Bot') } @</span>
                                        { moment.tz(conversation.datetime, 'Etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A') }
                                    </div>
                                    <div className="col-12">
                                        { conversation.type ?
                                            conversation.currentTask + ' ' + conversation.confidence
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            
        });
    };

    return (
        <div className='chat-box'>
            { mapConversations(props.dialogs) }
        </div>
    );
};

ChatBox.propTypes = {
    dialogs: PropTypes.array,
    userName: PropTypes.string,
};

export default ChatBox;
