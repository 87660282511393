import React from 'react';

import { connect } from 'react-redux';

import './NavigationPills.scoped.scss';

const NavigationPills = props => {

    if(props.loading)
        return null;

    const getPercentage = part => {
        const value = ( part / (props.total !== 0 ? props.total : 1) ) * 100;
        return `(${value.toFixed(2)}%)`;
    };

    return (
        <div className="row">
            {
                Object.entries(props.titles).map(([thisTypeOfEngagement, title]) => {
                    return(
                        <div
                            key={thisTypeOfEngagement}
                            className={`col-lg-${12/Object.keys(props.titles).length}`}
                        >
                            <div className={`card card-custom bg-${title.color} gutter-b ${props.typeOfEngagement === thisTypeOfEngagement ? 'selected' : ''}`}>
                                <span className="badge-message">
                                    <div
                                        className="btn btn-link"
                                        onClick={ () => { props.setTypeOfEngagement(thisTypeOfEngagement); } }
                                    >
                                        { title.label } : { props.engagements[thisTypeOfEngagement]?.length || 0 } { getPercentage(props.engagements[thisTypeOfEngagement]?.length || 0) }
                                    </div>
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setTypeOfEngagement: typeOfEngagement => dispatch({ type: 'SET_TYPE_OF_ENGAGEMENT', payload: { typeOfEngagement } }),
});

export default connect(null, mapDispatchToProps)(NavigationPills);
