import React from 'react';

import { useOktaAuth } from '@okta/okta-react';

import LoginLayout from '../Layouts/Login/Login';
import LoginForm from '../../components/Login/LoginForm';

function Login(props){

    const { oktaAuth } = useOktaAuth();

    React.useEffect( () => {
        oktaAuth.signInWithRedirect();
    }, [] );

    return (
        null
    );
};

export default Login;