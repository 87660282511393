import React from 'react';

import { NavLink as Link } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';

import { ReactComponent as Logo } from './naf.svg';

import './Sidebar.scoped.scss';

export default function Sidebar(){
    const { oktaAuth } = useOktaAuth();

    return (
        <div className="aside aside-left aside-fixed d-flex flex-column flex-row-auto" id="kt_aside">
            <div className="brand flex-column-auto" id="kt_brand">
                <Logo />
            </div>
            <div className="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="aside-menu my-4" data-menu-vertical="1" data-menu-scroll="1" data-menu-dropdown-timeout="500">
                    <ul className="menu-nav">
                        <li className="menu-item menu-item" aria-haspopup="true">
                            {/* <Link
                                to='/'
                                className="menu-link"
                                exact
                                activeClassName='active-link'
                            >
                                <span className="menu-text">Twilio Dashboard</span>
                            </Link> */}
                            <Link 
                                to='/'
                                className="menu-link"
                                exact
                                activeClassName='active-link'
                            >
                                <span className="menu-text">Dialogflow Dashboard</span>
                            </Link>
                            <Link 
                                to='/stats'
                                className="menu-link"
                                exact
                                activeClassName='active-link'
                            >
                                <span className="menu-text">Stats</span>
                            </Link>
                            {/* <Link
                                to='/flag'
                                className='menu-link'
                                exact
                                activeClassName='active-link'
                            >
                                <span className="menu-text">Flags</span>
                            </Link>
                            <Link
                                to='/fallback'
                                className='menu-link'
                                exact
                                activeClassName='active-link'
                            >
                                <span className="menu-text">Fallbacks</span>
                            </Link>*/}
                            <button 
                                className='btn menu-link logout' 
                                onClick={() => { oktaAuth.signOut(); }}
                            >
                                <span className="menu-text">Logout</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
