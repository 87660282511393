import React from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import moment from 'moment-timezone';

import { DateRange } from 'react-date-range';

import Spinner from '../../Generics/Spinner';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './CalendarBanner.scoped.scss';

const CalendarBanner = props => {

    const [ numberOfReceivedMessages, setNumberOfReceivedMessages ] = React.useState(0);
    const [ numberOfErrors, setNumberOfErrors ] = React.useState(0);

    React.useEffect(() => {
        const { StartDate, EndDate } = props.dates;
        onDateChange(StartDate, EndDate);
    }, []);

    const onDateChange = (StartDate, EndDate) => {
        if(StartDate && EndDate){
            getNumberOfReceivedMessages(StartDate, EndDate);
            getNumberOfErrorsForPeriod(StartDate, EndDate);
            props.setDates({ StartDate, EndDate });
        }
    };

    const getNumberOfReceivedMessages = async (StartDate, EndDate) => {
        try {
            const { data } = await axios({
                url: 'https://api.naf-x.com/ContactCenter/Dabble/GetLeads',
                method: 'GET',
                params: { startDt: StartDate, endDt: EndDate, routing: 1, DomainID: 68 },
                headers: { 'Api-Version': 1.0 }
            });
            if(data.length === 0) return;
            
            let nNumber = 0;
            for(const eng of data){
                if(eng.totalMessageCount)
                    nNumber += eng.totalMessageCount;
            }
            setNumberOfReceivedMessages(nNumber);
        } catch (error) {
            console.error(error);
        }
    };

    const getNumberOfErrorsForPeriod = async (StartDate, EndDate) => {
        try {
            const { data } = await axios({
                url: '/api/schedule/error/amount',
                method: 'GET',
                params: { StartDate, EndDate },
            });
            setNumberOfErrors(data);
        } catch (error) {
            console.error(error);
        }
    };
    
    return(
        <div className="row">
            <div className="col-lg-12">
                <div className="card card-custom bg-dark gutter-b">
                    <div className='d-flex row justify-content-between'>
                        <div className='d-flex flex-column col-12 col-xxl-8'>
                            <div>
                                <span className="badge-message title-message">
                                    Number of unique users engaged: { !props.loading ? props.total : <Spinner /> }
                                </span>
                            </div>
                            <span className='badge-message subtitle-message'>
                                Number of received messages in this period: { numberOfReceivedMessages }
                            </span>
                            <span className='badge-message subtitle-message'>
                                Number of endpoint errors in this period: { numberOfErrors }
                            </span>
                        </div>
                        <div className='col-12 col-xxl-4'>
                            <DateRange
                                editableDateInputs={true}
                                weekStartsOn={1}
                                onChange={({ selection: { startDate, endDate} }) => onDateChange(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))}
                                ranges={[{ startDate: moment(props.dates.StartDate).toDate(), endDate: moment(props.dates.EndDate).toDate(), key: 'selection' }]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setDates: dates => dispatch({ type: 'SET_DATES', payload: { dates } }),
});

export default connect(null, mapDispatchToProps)(CalendarBanner);
