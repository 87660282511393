import axios from 'axios';

export const getTwilioEngagements = (StartDate, EndDate) => {
    return async function(dispatch){
        dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: true } });
        try {
            const { data: engagements } = await axios({
                url: 'api/log/user/engaged',
                method: 'GET',
                params: { StartDate, EndDate }
            });
            dispatch({ type: 'SET_TWILIO_ENGAGEMENTS', payload: { engagements } });
        } catch (error) {
            console.log(error);
            dispatch({ type: 'SET_TWILIO_ENGAGEMENTS', payload: { engagements: [] } });        
        }
        finally {
            dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: false } });
        }
    }
};

export const getDialogflowEngagements = (StartDate, EndDate) => {
    return async function(dispatch){
        dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: true } });
        try {
            const { data: engagements } = await axios({
                url: 'api/dialogflow/user/engaged',
                method: 'GET',
                params: { StartDate, EndDate }
            });
            dispatch({ type: 'SET_DIALOGFLOW_ENGAGEMENTS', payload: { engagements } });
        } catch (error) {
            console.log(error);
            dispatch({ type: 'SET_DIALOGFLOW_ENGAGEMENTS', payload: { engagements: [] } });        
        }
        finally {
            dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: false } });
        }
    }
};

export const getAllEngagements = (StartDate, EndDate) => {
    return async function(dispatch){
        dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: true } });
        try {
            const [{ data: TwilioEngagements }, { data: DialogflowEngagements }] = await Promise.all([
                axios({
                    url: 'api/log/user/engaged',
                    method: 'GET',
                    params: { StartDate, EndDate }
                }),
                axios({
                    url: 'api/dialogflow/user/engaged',
                    method: 'GET',
                    params: { StartDate, EndDate }
                })
            ]);
            dispatch({ type: 'SET_TWILIO_ENGAGEMENTS', payload: { engagements: TwilioEngagements } });
            dispatch({ type: 'SET_DIALOGFLOW_ENGAGEMENTS', payload: { engagements: DialogflowEngagements } });
        } catch (error) {
            console.log(error);
            dispatch({ type: 'SET_TWILIO_ENGAGEMENTS', payload: { engagements: [] } });
            dispatch({ type: 'SET_DIALOGFLOW_ENGAGEMENTS', payload: { engagements: [] } });        
        }
        finally {
            dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: false } });
        }
    }
};

export const getFlaggedEngagements = () => {
    return async function(dispatch){
        dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: true } });
        try {
            const { data: engagements } = await axios({
                url: 'api/log/fails',
                method: 'GET',
            });
            dispatch({ type: 'SET_FLAGGED_ENGAGEMENTS', payload: { engagements } });

        } catch (error) {
            console.log(error);
            dispatch({ type: 'SET_FLAGGED_ENGAGEMENTS', payload: { schedules: [] } });        
        }
        finally {
            dispatch({ type: 'SET_ENGAGEMENTS_LOADING', payload: { loading: false } });
        }
    }
};

const engagements = {
    data: {
        twilio: {
            successful: [],
            fails: [],
            bails: [],
            fallbacks: [],
            rest: []
        },
        dialogflow: {
            successful: [],
            fails: [],
            bails: [],
            fallbacks: [],
            rest: []
        }
    },
    total: 0,
    loading: false,
    typeOfEngagement: 'successful',
    showSeenConversations: true,
};

export default function(state = engagements, action){
    switch(action.type){
        case 'SET_ENGAGEMENTS_LOADING': {
            return { ...state, loading: action.payload.loading };
        }
        case 'SET_TWILIO_ENGAGEMENTS': {
            state.data.twilio = { ...state.data.twilio, ...action.payload.engagements };
            return { ...state, total: action.payload.total };
        }
        case 'SET_DIALOGFLOW_ENGAGEMENTS': {
            state.data.dialogflow = { ...state.data.dialogflow, ...action.payload.engagements };
            return { ...state, total: action.payload.total };
        }
        case 'SET_TYPE_OF_ENGAGEMENT': {
            return { ...state, typeOfEngagement: action.payload.typeOfEngagement };
        }
        case 'SET_FLAGGED_ENGAGEMENTS':
            state.data.flagged = action.payload.engagements;
            return { ...state };
        case 'REVERSE_SHOW_SEEN_CONVERSATIONS':
            state.showSeenConversations = !state.showSeenConversations;
            return { ...state };
        default:
            return { ...state };
    }
}
