import React from 'react';

import { connect } from 'react-redux';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const SeenConversationCheck = props => {

    if(props.loading)
        return null;

    return (
        <div className='row'>
            <div className='col-lg-12 mb-4'>
                <FormControlLabel 
                    control={<Checkbox
                        checked={props.showSeenConversations}
                        onChange={() => props.setShowSeenConversations(!props.showSeenConversations)} 
                        name={'seenConversations'}
                    />}
                    label='Shown seen conversations'
                />
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setShowSeenConversations: () => dispatch({type: 'REVERSE_SHOW_SEEN_CONVERSATIONS' })
});

export default connect(null, mapDispatchToProps)(SeenConversationCheck);
