import React from 'react';

import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Link } from 'react-router-dom';

import Dashboard from '../Layouts/Dashboard';
import CalendarBanner from '../../components/Home/CalendarBanner/CalendarBanner';
import NavigationPills from '../../components/Home/NavigationPills/NavigationPills';
import LogDashboard from '../../components/Home/LogDashboard/LogDashboard';

import { getTwilioEngagements } from '../../reducers/engagements';

const TwilioDashboard = props => {

	const { StartDate, EndDate } = props.dates;

    React.useEffect(() => {
		props.getEngagements(StartDate, EndDate);
    }, [StartDate, EndDate]);

	const getDataByType = () => {
		switch(props.engagements.typeOfEngagement) {
			case 'successful':
				return props.engagements.data.twilio.successful.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						scheduledAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						scheduledFor: moment(`${engagement.ProposedDate} ${engagement.ProposedTime}`, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY hh:mm A'),
						seeConversation: <Link to={`twilio/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			case 'rest':
				return props.engagements.data.twilio.rest.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						firedAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						flagged: engagement.Flagged?.Status || 'NOT_SPECIFIED',
						seeConversation: <Link to={`twilio/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			case 'fails':
				return props.engagements.data.twilio.fails.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						firedAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						flagged: engagement.Flagged?.Status || 'NOT_SPECIFIED',
						seeConversation: <Link to={`twilio/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			case 'bails':
				return props.engagements.data.twilio.bails.map(
					engagement => ({
						cellphone: engagement.PhoneNumber,
						firedAt: moment.tz(engagement.FiredAt, 'etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm A'),
						flagged: engagement.Flagged?.Status || 'NOT_SPECIFIED',
						seeConversation: <Link to={`twilio/chat/${engagement.PhoneNumber}`}>See conversation</Link>
					})
				);
			default:
				return null;
		}
	};

	const titles = {
		fails: { label: 'Sent to a human', color: 'danger' },
		successful: { label: 'Successful schedules', color: 'success' },
		bails: { label: 'Bailed conversations', color: 'info' },
		rest: { label: 'Other interactions', color: 'warning' },
	};

    return(
		<Dashboard>
			<CalendarBanner 
				dates={props.dates}
				total={props.total}
			/>
			<NavigationPills 
				engagements={props.engagements.data.twilio}
				loading={props.engagements.loading}
				total={props.total}
				typeOfEngagement={props.engagements.typeOfEngagement}
				titles={titles}
			/>
			<LogDashboard 
				engagements={getDataByType()}
				loading={props.engagements.loading}
				typeOfEngagement={props.engagements.typeOfEngagement}
			/>
		</Dashboard>
    );
};

const mapStateToProps = state => {
	const total = Object.values(state.engagements.data.twilio).reduce((acum, currentValue) => acum += currentValue.length, 0);
    return { engagements: state.engagements, dates: state.dates, total };
};

const mapDispatchToProps = dispatch => ({
	getEngagements: (StartDate, EndDate) => dispatch(getTwilioEngagements(StartDate, EndDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(TwilioDashboard);
