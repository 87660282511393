import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import moment from 'moment-timezone';

import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import './LogDashboard.scoped.scss';
import './LogDashboard.css';

const LogDashboard = props => {

    const engagements = props.engagements.filter(engagement => props.showSeenConversations ? true : (engagement.status !== 'Seen'));
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const page = parseInt(query.get('page')) || 0;
    
    const sortDates = order => {
        return ({ data: obj1 }, { data: obj2}) => {
            if(obj1 === 'No date provided') return 1;
            if(obj2 === 'No date provided') return -1;
            const date1 = moment(obj1, 'MM/DD/YYYY h:mm A');
            const date2 = moment(obj2, 'MM/DD/YYYY h:mm A');
            return (date1.isBefore(date2) ? 1 : -1) * (order === 'asc' ? -1 : 1);
        };
    };

    const getSortOrderByType = () => {
        switch(props.typeOfEngagement) {
            case 'successful':
                return { name: 'scheduledAt', direction: 'desc' };
            default:
                return { name: 'firedAt', direction: 'desc' };
        }
    };

    const getColumnsByType = () => {
        switch(props.typeOfEngagement) {
            case 'successful':
                return [
                    { name: 'cellphone', label: 'Cellphone', options: { filter: true, sort: true, setCellProps: (value, rowIndex) => ({ className: 'engagement-cell-' + engagements[rowIndex].status }) } },
                    { name: 'scheduledAt', label: 'Scheduled at', options: { filter: true, sort: true, sortCompare: sortDates, setCellProps: (value, rowIndex) => ({ className: 'engagement-cell-' + engagements[rowIndex].status })  } },
                    { name: 'scheduledFor', label: 'Scheduled for', options: { filter: true, sort: true, sortCompare: sortDates, setCellProps: (value, rowIndex) => ({ className: 'engagement-cell-' + engagements[rowIndex].status }) } },
                    { name: 'seeConversation', label: 'See conversation', options: { filter: false, sort: false } },
                ];
            default:
                return [
                    { name: 'cellphone', label: 'Cellphone', options: { filter: true, sort: true, setCellProps: (value, rowIndex) => ({ className: 'engagement-cell-' + engagements[rowIndex].status }) } },
                    { name: 'firedAt', label: 'Fired at', options: { filter: true, sort: true, sortCompare: sortDates, setCellProps: (value, rowIndex) => ({ className: 'engagement-cell-' + engagements[rowIndex].status }) } },
                    { name: 'flagged', label: 'Flagged', options: { filter: true, sort: true, setCellProps: (value, rowIndex) => ({ className: 'engagement-cell-' + engagements[rowIndex].status }) } },
                    { name: 'seeConversation', label: 'See conversation', options: { filter: false, sort: false } },
                ];
        }
    };

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    fontWeight: 'bold',
                    fontSize: "12px"
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    fontSize: "12px"
                }
            }
        }
    });

    const options = {
        download: false,
        print: false,
        selectableRows: 'none',
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 75, 100],
        sortOrder: getSortOrderByType(),
        page,
        onChangePage: (currentPage) => history.push({ search: '?page=' + currentPage }),
    };

    if(props.loading){
        return(
            <React.Fragment>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </React.Fragment>
        );
    }

    else if(!props.loading && engagements == null){
        return null;
    }
    
    return(
        <React.Fragment>
            <div className='row'>
                <div className='col-12'>
                    <MuiThemeProvider theme={ getMuiTheme() }>
                        <MUIDataTable
                            title=''
                            data={ engagements }
                            columns={ getColumnsByType() }
                            options={ options }
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        </React.Fragment>
    );
};

LogDashboard.propTypes = {
    engagements: PropTypes.array,
    loading: PropTypes.bool,
    typeOfEngagement: PropTypes.string,
    showSeenConversations: PropTypes.bool,
};

export default LogDashboard;
