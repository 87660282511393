import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import axios from 'axios';

import ChatBox from './ChatBox';
import Spinner from 'components/Generics/Spinner';

import './Penetration.scoped.scss';

const PenetrationBoard = props => {

    const [penetrationData, setPenetrationData] = React.useState();
    const [numberOfPensToShow, setNumberOfPensToShow] = React.useState(5);
    const [nafScheduleData, setNafScheduleData] = React.useState();
    const [dabbleScheduling, setDabbleScheduling] = React.useState({ data: {}, loading: false });

    React.useEffect(() => {
        getUserData(props.phoneNumber);
        axios.post('https://api.naf-x.com/ContactCenter/Dabble/GetScheduledAppointment', 
        {
            phoneNumber: props.phoneNumber.substring(2),
            DomainID: 68
        },
        { 
            headers: { 'api-version': 1.0 }
        })
        .then(response => {
            const { data } = response;
            if(data.scheduleDate && data.scheduleTime){
                const datetime = data.scheduleDate.split('T')[0] + 'T' + data.scheduleTime;
                setNafScheduleData(moment(datetime).format('MM/DD/YYYY hh:mm A'));
            }
        })
    }, [props.phoneNumber]);

    const getUserData = async phoneNumber => {
        try {
            setDabbleScheduling({ loading: true });
            axios({
                url: 'https://api.naf-x.com/ContactCenter/Dabble/GetScheduledCallBreakDown',
                method: 'GET',
                params: { PhoneNumber: phoneNumber.substring(2) },
                headers: {
                    'api-version': '1.0'
                }
            })
                .then(({ data: penetrationData }) => {
                    setPenetrationData(penetrationData);
                    setLoading(false);
                })
                .catch(console.error)
            axios.get(props.dialogUrl + `/${phoneNumber}/chats`)
                .then(({ data: { dialogs, schedule } }) => {
                    setDabbleScheduling({ data: { schedule, dialogs }, loading: false });
                })
                .catch(console.error);
        } catch (error) {
            console.error(error);
        }
    };

    const mapPenetration = penetration => {
        const penetrationMapFunc = (p, index) => {
            return (
                <div
                    key={index}
                    className="d-flex align-items-center penetration-item"
                >
                    <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                    <div className="d-flex flex-column flex-grow-1 mx-4">
                        <span className="text-dark-75 font-weight-bold font-size-lg mb-1">Phone number status: {p.phoneNumberStatus}</span>
                        <span className="text-muted font-weight-bold">Phone number type: {p.phoneNumberType}</span>
                        <span className="text-muted font-weight-bold">Phone number status: {p.phoneNumberStatus}</span>
                        <span className="text-muted font-weight-bold">Phone number category: {p.phoneNumberCategory}</span>
                        <span className="text-muted font-weight-bold">Phone number campaign: {p.phoneNumberCampaign}</span>
                        <span className="text-muted font-weight-bold">Call time: {moment.tz(p.phoneNumberUTCCallTime, 'Etc/UTC').tz('America/Los_Angeles').format('MM/DD/YYYY h:mm A')}</span>
                    </div>
                </div>
            );
        };
        return (
            <>
                <div className='col-12'>
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-body pt-2">
                            { penetration.slice(0, numberOfPensToShow).map(penetrationMapFunc) }
                            { (penetration.length > numberOfPensToShow) && <button className='btn btn-primary' onClick={() => setNumberOfPensToShow(numberOfPensToShow + 5)}>Show more</button> }
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            { penetrationData &&
                <>
                    <h1>#{penetrationData.customerID}: {penetrationData.customerName}</h1>
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <span>Phone Number: {props.phoneNumber}</span>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <span>Customer status: {penetrationData.customerContactStauts}</span>
                        </div>
                        <div className="col-lg-12 col-md-6">
                            <span>Campaign Name: {penetrationData.scheduling.campaignName}</span>
                        </div>
                        {
                            penetrationData.schedule ?
                                <div className="col-lg-12 col-md-6">
                                    <span>Locally Scheduled for: {moment(penetrationData.schedule.ProposedDate + ' ' + penetrationData.schedule.ProposedTime).format('MM/DD/YYYY hh:mm A')}</span>
                                </div>
                                :
                                null
                        }
                        {
                            nafScheduleData ?
                                <div className="col-lg-12 col-md-6">
                                    <span>Schedule in BV: {nafScheduleData}</span>
                                </div>
                                :
                                null
                        }
                    </div>
                </>
                || <Spinner />
            }
            <div className='row' style={{ marginTop: '3%' }}>
                    <div className="col-5">
                        { penetrationData?.penetration &&
                            <>
                            <h3 className="card-title font-weight-bolder text-dark">Penetration:</h3>
                            <div className="d-flex row">
                                { mapPenetration(penetrationData.penetration) }
                            </div>
                            </>
                            || <Spinner />
                        }
                        </div>
                    <div className='col-7'>
                        {  !dabbleScheduling.loading && dabbleScheduling.data.dialogs &&
                        <>
                            <h3 className="card-title font-weight-bolder text-dark">Chat:</h3>
                            <ChatBox 
                                dialogs={dabbleScheduling.data.dialogs}
                                userName={penetrationData?.customerName || ''}
                            />
                        </>
                        || <Spinner />
                        }
                    </div>
            </div>
        </>
    );
};

PenetrationBoard.propTypes = {
    phoneNumber: PropTypes.string.isRequired,
};

export default PenetrationBoard;
