import React from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import Dashboard from '../Layouts/Dashboard';
import PenetrationBoard from '../../components/Chat/PenetrationBoard';

import './Chat.scoped.scss';

const Chat = props => {

    const { phoneNumber } = useParams();

    React.useEffect(() => {
        axios.put(`${props.dialogUrl}/${phoneNumber}/status`);
    }, []);

    return (
        <Dashboard>
            <div className="box">
                <PenetrationBoard 
                    phoneNumber={ phoneNumber } 
                    dialogUrl={ props.dialogUrl }
                />
            </div>
        </Dashboard>
    );
};

export default Chat;
