import React from 'react';

import Sidebar from './Sidebar/Sidebar';

import './Dashboard.scoped.scss';

export default function DashboardLayout(props){

    return (
        <div className="d-flex flex-column flex-root dashboard-body">
            <div className="d-flex flex-row flex-column-fluid page">
                <Sidebar />
                <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                    { props.children }
                </div>
            </div>
        </div>
    );
};