import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import moment from 'moment-timezone';
import { Chart } from "react-google-charts";

import Dashboard from 'pages/Layouts/Dashboard';
import CalendarBanner from 'components/Home/CalendarBanner/CalendarBanner';
import Spinner from 'components/Generics/Spinner';

import { getAllEngagements } from 'reducers/engagements';

import './Stats.scoped.scss';

const Stats = props => {

    const { StartDate, EndDate } = props.dates;

    const expandedStats = [
        {
            label: 'Wanted to unsubscribe',
        },
        {
            label: 'Welcomes/Introductions',
        },
        { 
            label: 'Transfered to humans',
        },
        { 
            label: 'Wants to cancel appointment',
        },
        {
            label: 'No one called',
        },
        {
            label: 'Insults',
        },
        {
            label: 'Schedules',
        },
        {
            label: 'Bails',
        },
        {
            label: 'Other successfull events',
        },
        {
            label: 'Unresolved/Failed',
        }
    ];

    const [typeOfView, setTypeOfView] = React.useState('default');
    const [isStacked, setIsStacked] = React.useState(false);
    const [chartData, setChartData] = React.useState([]);

    React.useEffect(() => {
        props.getEngagements(StartDate, EndDate);
    }, [StartDate, EndDate]);

    React.useEffect(() => {
        getDataFromEngagements();
    }, [StartDate, EndDate, typeOfView]);

    const expandedSortFn = (engagements, callback) => {
        const expandendWithAmount = {};
        // expandedStats.forEach(ex => expandendWithAmount[ex.label] = 0);
        // const numberSchedules = engagements.successful.map(e => e.PhoneNumber);
        // mainloop:
        // for (const engagement of Object.values(engagements).flat()) {
        //     if(engagement.Intent.includes('schedule')){
        //         {
        //             const index = numberSchedules.indexOf(engagement.PhoneNumber);
        //             if (index !== -1) {
        //                 expandendWithAmount['Schedules']++
        //             } else {
        //                 expandendWithAmount['Bails']++
        //             }
        //             continue;
        //         }
        //     }
        //     for(const { intents, label } of expandedStats){
        //         if(intents?.includes(engagement.Intent)){
        //             expandendWithAmount[label]++;
        //             continue mainloop;
        //         }
        //     }
        //     expandendWithAmount['Unresolved/Failed']++;
        // }
        for(const bucket of Object.values(engagements)){
            for(const interaction of bucket){
                if(expandendWithAmount[interaction.Label] != null)
                    expandendWithAmount[interaction.Label]++;
                else
                    expandendWithAmount[interaction.Label] = 1;
            }
        }
        return callback(expandendWithAmount);
    };

    const getDataInAggregateView = (engagements, momentFromStart, i) => {
        return [momentFromStart.clone().add(i, 'days').format('MMM DD'), engagements.successful.length, engagements.bails.length, engagements.fails.length];
    };

    const getDataInExpandedView = (engagements, momentFromStart, i) => {
        const res = [momentFromStart.clone().add(i, 'days').format('MMM DD')];
        expandedSortFn(engagements, expandendWithAmount => {
            // TODO: Instead of relying on the label of expandedStats we need to extract all the labels for the time period 
            for(const { label } of expandedStats){
                res.push(expandendWithAmount[label] || 0);
            }
        });
        return res;
    };

    const getDataFromEngagements = async () => {
        const momentFromStart = moment(StartDate, 'YYYY-MM-DD');
        const momentFromEnd = moment(EndDate, 'YYYY-MM-DD');
        const arr = [];
        for (let i = 0; i <= momentFromEnd.diff(momentFromStart, 'days'); i++) {
            try {
                const { data: engagements } = await axios.get('api/dialogflow/user/engaged', { params: { StartDate: momentFromStart.clone().add(i, 'days').format('YYYY-MM-DD'), EndDate: momentFromStart.clone().add(i, 'days').format('YYYY-MM-DD') } })
                arr.push(
                    typeOfView === 'default' ? getDataInAggregateView(engagements, momentFromStart, i) : getDataInExpandedView(engagements, momentFromStart, i)
                );
            } catch (error) {
                console.error(error);
            }
        }
        setChartData(arr);
    };

    const getAggregateStats = () => {
        const aggregateTotal = Object.entries(props.engagements.data.twilio).reduce((acum, [key, value]) => { if (key !== 'fallbacks') { return acum += value.length } return acum; }, 0)
                                + Object.entries(props.engagements.data.dialogflow).reduce((acum, [key, value]) => { if (key !== 'fallbacks') { return acum += value.length } return acum; }, 0)
        return(
            <>
                <div className="row">
                    <div className="col-lg-6">
                        <h1>Dialogflow</h1>
                        <div className='d-flex flex-column stats-numbers'>
                            <span className='lead'>Bot Successes:</span>
                            <div>
                                <span className='number'>{props.engagements.data.dialogflow.successful.length}</span>
                                <span className='ml-3 percentage'>({((props.engagements.data.dialogflow.successful.length / props.total) * 100).toFixed(1)}%)</span>
                            </div>
                            <span className='lead'>Bot bails:</span>
                            <div>
                                <span className='number'>{props.engagements.data.dialogflow.bails.length}</span>
                                <span className='ml-3 percentage'>({((props.engagements.data.dialogflow.bails.length / props.total) * 100).toFixed(1)}%)</span>
                            </div>
                            <span className='lead'>Unresolved/failed conversations:</span>
                            <div>
                                <span className='number'>{props.engagements.data.dialogflow.fails.length}</span>
                                <span className='ml-3 percentage'>({((props.engagements.data.dialogflow.fails.length / props.total) * 100).toFixed(1)}%)</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h1>Twilio</h1>
                        <div className='d-flex flex-column stats-numbers'>
                            <span className='lead'>Bot Successes:</span>
                            <span className='number'>{props.engagements.data.twilio.successful.length + props.engagements.data.twilio.fails.length}</span>
                            <span className='lead'>Bot bails:</span>
                            <span className='number'>{props.engagements.data.twilio.bails.length}</span>
                            <span className='lead'>Bot didnt understand:</span>
                            <span className='number'>{props.engagements.data.twilio.fallbacks.length}</span>
                            <span className='lead'>Rest:</span>
                            <span className='number'>{props.engagements.data.twilio.rest.length}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='d-flex flex-column stats-numbers'>
                        <span className='lead'>Total (Twilio + Dialogflow):</span>
                        <span className='number total-number'>{aggregateTotal}</span>
                    </div>
                </div>
            </>
        );
    };

    const getExpandedStats = () => {
        return expandedSortFn(props.engagements.data.dialogflow, expandendWithAmount =>
            (
                <>
                    <div className="row">
                        <div className='d-flex flex-column stats-numbers'>
                            { 
                                Object.entries(expandendWithAmount).map(([label, amount]) => (
                                    <React.Fragment key={label}>
                                        <span className='lead'>{label}:</span>
                                        <div>
                                            <span className='number'>{amount}</span>
                                            <span className='ml-3 percentage'>({((amount / props.total) * 100).toFixed(1)}%)</span>
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className='d-flex flex-column stats-numbers'>
                            <span className='lead'>Total (Dialogflow):</span>
                            <span className='number total-number'>{Object.values(expandendWithAmount).reduce((acum, cv) => acum + cv, 0)}</span>
                        </div>
                    </div>            
                </>
            )
        );
    };

    return (
        <Dashboard>
            <div
                className='stats-container card-body'
            >
                <CalendarBanner
                    dates={props.dates}
                    total={props.total}
                />
                
                { !props.engagements.loading &&
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="d-flex flex-row mb-3 justify-content-between">
                                    <div>
                                        <button
                                            className={`btn btn-${typeOfView === 'default' ? 'primary' : 'link'}`}
                                            onClick={() => setTypeOfView('default')}
                                        >
                                            Aggregate View
                                        </button>
                                        <button
                                            className={`btn btn-${typeOfView === 'expanded' ? 'primary' : 'link'}`}
                                            onClick={() => setTypeOfView('expanded')}
                                        >
                                            Expandend View
                                        </button>
                                    </div>
                                    <div>
                                        <button 
                                            type='button'
                                            className='btn btn-primary ml-5'
                                            onClick={() => setIsStacked(!isStacked)}
                                        >
                                            Stack?
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-4'>
                                {
                                    typeOfView === 'default' &&
                                    getAggregateStats()
                                }
                                {
                                    typeOfView === 'expanded' &&
                                    getExpandedStats()
                                }
                            </div>    
                            <div className="col-lg-8 chart-container">
                                <Chart
                                    chartType="ColumnChart"
                                    data={[typeOfView === 'default' ? ['date', 'Success', 'Bails', 'Fails'] : ['date', ...expandedStats.map(es => es.label)], ...chartData]}
                                    height={'100%'}
                                    width={'100%'}
                                    options={{
                                        isStacked,
                                        chartArea: {
                                          width: '70%'  
                                        },
                                        legend: { position: 'bottom' },
                                        hAxis: { title: 'Dates' },
                                        vAxis: { title: 'Amount' }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                || <Spinner /> }
            </div>
        </Dashboard>
    );
};

const mapStateToProps = state => {
    const total = 
        Object.entries(state.engagements.data.twilio).reduce((acum, [key, value]) => { if (key !== 'fallbacks') { return acum += value.length } return acum; }, 0)
        + Object.entries(state.engagements.data.dialogflow).reduce((acum, [key, value]) => { if (key !== 'fallbacks') { return acum += value.length } return acum; }, 0)
    return { engagements: state.engagements, dates: state.dates, total };
};

const mapDispatchToProps = dispatch => ({
    getEngagements: (StartDate, EndDate) => dispatch(getAllEngagements(StartDate, EndDate))
});

Stats.propTypes = {
    engagements: {
        data: {
            twilio: {},
            dialogflow: {}
        },
        loading: PropTypes.bool,
        typeOfEngagement: PropTypes.string,
    },
    total: PropTypes.number,
    dates: {
        StartDate: PropTypes.string,
        EndDate: PropTypes.string
    },
    getEngagements: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Stats);
