import './Style.scss';

import React from 'react';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, useHistory, withRouter } from 'react-router-dom';

import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import reducer from './reducers';

import TwilioDashboard from './pages/Home/TwilioDashboard';
import DialogflowDashboard from './pages/Home/DialogflowDashboard';
import Stats from './pages/Stats/Stats';
import Chat from './pages/Chat/Chat';
import Login from './pages/Login/Login';

import './interceptors/axios';

const AppWithRouter = withRouter(() => {
    const {
        REACT_APP_OKTA_CLIENT_ID: clientId,
        REACT_APP_OKTA_DOMAIN: oktaDomain,
    } = process.env;
    const redirectUri =  window.location.protocol + "//" + window.location.host + '/implicit/callback';
    const issuer = `${oktaDomain}/oauth2/default`;
    const oktaAuth = new OktaAuth({
        issuer,
        clientId,
        redirectUri,
    });

    const history = useHistory();

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
        >
            {/* <SecureRoute path='/' component={ TwilioDashboard } exact /> */}
            <SecureRoute path='/' component={ DialogflowDashboard } exact />
            <SecureRoute path='/stats' component={ Stats } exact />
            <Route path="/login" component={ Login } />
            <SecureRoute path="/twilio/chat/:phoneNumber" component={ () => <Chat dialogUrl={'/api/log'} /> } exact />
            <SecureRoute path="/dialogflow/chat/:phoneNumber" component={ () => <Chat dialogUrl={'/api/dialogflow'} /> } exact />
            <Route path='/implicit/callback' component={ LoginCallback } />
        </Security>
    );
});

const App = () => {
    const arg = window.__REDUX_DEVTOOLS_EXTENSION__ ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__()) : applyMiddleware(thunk);

    const persistConfig = {
        key: 'root',
        storage,
    };

    const persistedReducer = persistReducer(persistConfig, reducer);

    const store = createStore(persistedReducer, arg);
    const persistor = persistStore(store);

    return (
        <Provider store={store}>
            <PersistGate
                loading={null} 
                persistor={persistor}
            >
                <Router>
                    <AppWithRouter />
                </Router>
            </PersistGate>
        </Provider>
    );
};

export default App;
